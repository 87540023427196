<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary font-weight-bold"> Carstock </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>

            <b-breadcrumb-item active> Elenco Carstock </b-breadcrumb-item>
            <b-breadcrumb-item v-if="step == 2" active>
              Pubblicazione offerta
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="carstock_list"
        :filter="filter"
        :busy="loading"
        show-empty
        striped
        responsive
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle offerte...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <h5 class="text-primary-light my-2">Non ci sono offerte da mostrare</h5>
          </div>
        </template>
        <template #cell(id_stock)="data">
            {{data.item.id_carstock}}
        </template>
        <template #cell(data_fine)="data">
            {{data.item.data_scadenza}}
        </template>
        <template #cell(opzioni)="data">
          <feather-icon
            icon="FileTextIcon"
            class="cursor-pointer mr-2"
            size="20"
            @click="$router.push('/carstock/' + data.item.id_carstock)"
          />
          <feather-icon
            icon="Trash2Icon"
            class="cursor-pointer text-danger"
            size="20"
            @click="deleteAlert(data.item.id_carstock)"
          />
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BBreadcrumb,
  BBreadcrumbItem,
  BTable,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

import { Requests } from "@/api";
import 'sweetalert2/src/sweetalert2.scss'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BImg,
    BFormGroup,
    BFormInput,
  },

  data: () => ({
    step: 1,
    carstock_list: [],
    selected_auto: [],
    carstock_total: 0,
    filter: "",

    fields: [
      {
        key: "id_stock",
        sortable: true,
      },
      {
        key: "nome_offerta",
        sortable: true,
      },
      {
        key: "auto_in_stock",
        sortable: true,
      },
      {
        key: "prezzo_rivenditore",
        sortable: true,
      },
      {
        key: "data_fine",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    //Helpers

    loading: false,
    delete_loading: false,
  }),

  created() {
    this.getCarStock();
  },

  methods: {
    async getCarStock() {
      this.loading = true;

      try {
        const response = await Requests.getCarStock();
        this.carstock_list = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async deleteCarStock(id) {
      this.delete_loading = true;

      let request_data = new FormData();

      request_data.append('id_carstock', id)

      try {
        await Requests.deleteCarStock(request_data);
        this.$swal.fire("Offerta eliminata!", "", "success");
        const index = this.carstock_list.indexOf(id);
        this.carstock_list.splice(index, 1)
      } catch (err) {
        console.debug(err);
      }

      this.delete_loading = false;
    },

    async deleteAlert(id) {
        this.$swal
        .fire({
            title: "Elimina Offerta",
            text: "Sei sicuro di voler eliminare quest'offerta?",
            showCancelButton: true,
            cancelButtonText: "Annulla",
            confirmButtonText: "Elimina",
            customClass: {
                title: "text-primary-light",
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-secondary'
            },
        })
        .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.deleteCarStock(id)
            }
        });
    }
  },
};
</script>
